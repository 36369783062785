import {ApiRoutesService} from './api-routes.service';
import {AuthService} from './auth.service';
import {DoctypeService} from './doctype.service';
import {ErrorService} from './error.service';
import {GlobalErrorService} from './global-error.service';
import {GlobalStorageService} from './global-storage.service';
import {LandingpageService} from './landingpage.service';
import {NewPartnerService} from './new-partner.service';
import {NotificationService} from './notification.service';
import {ProgressService} from './progress.service';
import {WelcomeService} from './welcome.service';
import {EditorService} from './editor.service';
import {GenerateService} from './generate.service';
import {ExistingPartnerService} from './existing-partner.service';
import {AuthGuard} from '../guards/auth.guard';
import {WelcomeGuard} from '../guards/welcome.guard';
import {StateGuard} from '../guards/state.guard';
import {TitleService} from './title.service';
import {ClientService} from './client.service';
import {StateService} from "./state.service";

export const Services = [
  ApiRoutesService,
  AuthService,
  DoctypeService,
  ErrorService,
  GlobalErrorService,
  GlobalStorageService,
  LandingpageService,
  NewPartnerService,
  NotificationService,
  ProgressService,
  WelcomeService,
  EditorService,
  GenerateService,
  ExistingPartnerService,
  AuthGuard,
  WelcomeGuard,
  StateGuard,
  TitleService,
  ClientService,
  StateService
];
