import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ApiRoutesService} from './api-routes.service';

@Injectable({
  providedIn: 'root'
})
export class DoctypeService {

  constructor(
    private http: HttpClient,
    private apiRoutes: ApiRoutesService,
  ) {
  }

  public uploadAdditional = (file: FormData) => this.http.post(this.apiRoutes.UPLOAD_ADDITIONAL, file, {
    reportProgress: true, observe: 'events'
  });

  public predict = (doctypeName: string, context: string, version: number, supplierName: string) => this.http.post(this.apiRoutes.PREDICT,
    {docType: doctypeName, context, version, supplierName});

}
