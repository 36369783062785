<div class="bg-faded">
	<nav id="topNav" class="navbar navbar-toggleable-xl fixed-top">
		<div class="head-wrap">
			<a class="navbar-brand" [routerLink]="['']">
				<img src="/assets/images/netfira_logo_primary.svg" width="230px" />
			</a>
			<div class="head-semiwrap">
				<ul class="navbar-nav d-flex flex-row flex-nowrap align-items-center ml-auto">
					<li class="nav-item" *ngIf="!!currentUserName">
						<a class="nav-link nav-link-custom page-scroll position-relative" [routerLink]="['/landing']">{{ 'global.home' | translate }}</a>
					</li>
					<li class="nav-item" *ngIf="!!currentUserName">
						<a class="nav-link nav-link-custom page-scroll position-relative" [routerLink]="['/dashboard']">{{ 'global.dashboard' | translate }}</a>
					</li>
					<li class="nav-item">
						<a class="nav-link nav-link-custom page-scroll" href="https://netfira.atlassian.net/servicedesk" target="_blank">{{
							'global.help' | translate
						}}</a>
					</li>
					<li class="nav-item">
						<a class="nav-link nav-link-custom page-scroll" (click)="onChangeLang()">EN/DE</a>
					</li>
					<li class="nav-item" *ngIf="!!currentUserName">
						<a class="nav-link nav-link-custom page-scroll" [routerLink]="['/logout']">{{ 'global.logout' | translate }}</a>
					</li>
				</ul>
				<button class="navbar-toggler" type="button">☰</button>
			</div>
		</div>
	</nav>
	<section class="content" [@myAnimation]="o.isActivated ? o.activatedRoute : ''">
		<router-outlet d="view" #o="outlet"></router-outlet>
	</section>

	<footer class="footer">
		<span>&copy; {{ year }} Netfira GmbH | v{{ appVersion }}</span>
	</footer>
	<ngx-snackbar [position]="'bottom-center'" [max]="1"></ngx-snackbar>
</div>
