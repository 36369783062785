import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiRoutesService } from './api-routes.service';
import { GlobalErrorService } from './global-error.service';
import { GlobalStorageService } from './global-storage.service';
import { UploadPDFResponse } from '../interfaces/uploadResponce';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ExistingPartnerService {
	constructor(private http: HttpClient, private apiRoutes: ApiRoutesService, private globalError: GlobalErrorService, private globalStorageService: GlobalStorageService) {}

	checkSupplier(supplierNumber: string) {
		const params = new HttpParams().set('supplierNumber', supplierNumber);
		return this.http.get(`${this.apiRoutes.SUPPLIER}`, { params });
	}

	uploadFile = (data: FormData, pages?: string[], ocrConvert?: Boolean): Observable<UploadPDFResponse[]> => {
		if (pages) {
			data.append('range', JSON.stringify(pages));
		}

		if (ocrConvert) {
			data.append('ocrConvert', JSON.stringify(ocrConvert));
		}

		return this.http.post<UploadPDFResponse[]>(this.apiRoutes.UPLOAD, data);
	};

	readFile = (file: File): Promise<any> => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onerror = () => this.globalError.handleError(new Error('global.default_error_message'));
		return new Promise((resolve) => {
			reader.onload = () => {
				const readerResult = reader.result.toString().replace(/^data:application\/pdf;base64,/, '');
				this.globalStorageService.setConfigKey('base64', readerResult);
				resolve(true);
			};
		});
	};
}
