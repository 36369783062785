import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree} from '@angular/router';

import {CanActivate} from '@angular/router';
import {GlobalStorageService} from '../services/global-storage.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateGuard implements CanActivate {

  path: any;
  route: UrlTree;


  constructor(
    private router: Router,
    private globalStorageService: GlobalStorageService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.route = this.router.parseUrl(state.url);
    const component = this.route.root.children['primary'].segments[0].path;
    const permission = this.globalStorageService.getState(component.toString());
    if (permission) {
      return permission;
    } else {
      this.router.navigate(['landing']);
      return false;
    }
  }

}
