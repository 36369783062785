import {GlobalStorageService} from './global-storage.service';
import {HttpClient} from '@angular/common/http';
import {ApiRoutesService} from './api-routes.service';
import {AppState, SessionInterface} from '../interfaces/session';
import {fromEvent, Observable, of, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {catchError, map, mergeMap, share, switchMap, tap} from "rxjs/operators";
import {AuthService} from "./auth.service";
import * as jwtDecode from 'jwt-decode';
import {LandingpageService} from "./landingpage.service";
import {Injectable} from "@angular/core";
import {DbTokenService} from "./dbToken.service";
import {PayloadJWT} from "../interfaces/payloadJWT";
import { Logger } from 'ag-grid-community';

@Injectable()
export class StateService {

  private _isStateChanged: boolean = false;
  private url = '';
  private sessionTimeout = null;
  private currentSession: SessionInterface = {
    _id: null,
    clientId: null,
    createdAt: null,
    updatedAt: null,
    data: null,
    locked: null
  };

  constructor(
    protected globalStorage: GlobalStorageService,
    protected http: HttpClient,
    protected apiRoutes: ApiRoutesService,
    protected router: Router,
    protected authService: AuthService,
    protected landingpageService: LandingpageService,
    protected dbTokenService: DbTokenService
  ) {
    this.url = apiRoutes.SESSIONS;
  }

  private decodeJWT(JWT: string): PayloadJWT {
    try {
      const payload = jwtDecode(JWT);
      return payload;
    } catch (e) {
      this.authService.logout();
    }
  }

  public lockSession(): Observable<any> {
    const url = this.apiRoutes.SESSIONS + `/${this.currentSession._id}/lock`;
    return this.http.patch(url, {}).pipe(catchError(err => {
      clearTimeout(this.sessionTimeout);
      return throwError(err);
    }), tap(d => {
      this.onLockSession();
    }), share());
  }

  private onLockSession() {
    clearTimeout(this.sessionTimeout);
    this.sessionTimeout = setTimeout(() => {
      this.lockSession().subscribe(d => d);
    }, 60000);
  }

  checkTokenRefresh() {
    fromEvent(window, 'storage').subscribe((data: any) => {
      if (data.key != 'JWT') {
        return;
      }
      if (data.oldValue != null && data.newValue != '' && (data.newValue != data.oldValue)) {
        this.currentSession._id = null;
        // If session was rejected then go to landing page
        if (!this._isStateChanged) {
          // If current page is landing then reload page.
          if (this.router.url != '/landing') {
            this.router.navigate(['']);
          } else {
            window.location.reload();
          }
        } else {
          try {
            this.authService.setCurrentUser(this.decodeJWT(data.newValue));
          } catch (e) {
            this.authService.logout();
          }
        }
      } else {
        clearTimeout(this.sessionTimeout);
      }
      this._isStateChanged = false;
    });
  }

  resetSessionId() {
    this._isStateChanged = true;
    return this.authService.onRefreshToken().pipe(tap(d => {
        if (d) {
          clearTimeout(this.sessionTimeout);
          this.resetSession();
        }
      }
    ))
  }

  updateSate(data: {}) {
    data = {...data, config: this.globalStorage.getConfigObj()};
    this.http.put<SessionInterface>(this.url + `/${this.currentSession._id}?lock=true`, data)
      .subscribe(session => {
        this.onLockSession();
      });
  }

  getSessionsList(): Observable<SessionInterface[]> {
    return this.http.get<SessionInterface[]>(this.url);
  }

  removeSession(sessionId: string) {
    return this.http.delete(this.url + '/' + sessionId);
  }

  getSessionById(sessionId: string): Observable<SessionInterface> {
    return this.http.get<SessionInterface>(this.apiRoutes.SESSIONS + `/${sessionId}`, {params: {lock: 'true'}}).pipe(map((session: SessionInterface) => {
      if (session != null) {
        session.locked = true;
      }
      return session;
    }));
  }

  getCurrentSession(): Observable<SessionInterface> {
    const sessionId = this.getSessionId();
    return this.getSessionById(sessionId).pipe(catchError(e => {
      if (e.status == 404) {
        this.currentSession._id = sessionId;
        this.currentSession.locked = false;
        return of(this.currentSession);
      }
      if (e.status == 403) {
        return of(null);
      }
      return throwError(e);
    }), mergeMap((session: SessionInterface) => {
      if (!session)
        return this.authService.onRefreshToken().pipe(map((refresh: boolean) => {
          if (refresh) {
            this.currentSession = session;
            return this.currentSession;
          }
        }));
      this.currentSession = session;
      if (session.locked)
        this.onLockSession();
      return of(session);
    }));
  }

  getSessionId(): string {
    return (this.currentSession._id) ? this.currentSession._id : this.decodeJWT(this.dbTokenService.getToken()).sessionId;
  }

  resumeSession(sessionId: string) {
    return this.getSessionById(sessionId).pipe(
      switchMap((session: SessionInterface) => {
        if (session.data.config['predictionAccepted'] == undefined) {
          return this.landingpageService.getClientConfig().pipe(map(config => {
            session.data.config = {...session.data.config, predictionAccepted: config['predictionAccepted']}
            return session;
          }))
        }
        return of(session);
      }),
      tap((session: SessionInterface) => {
        session.locked = true;
        this._isStateChanged = true;
        this.authService.onChangeSession(sessionId).subscribe(d => {
          if (d != null) {
            this.currentSession = session;
            this.onLockSession();
            this.resumeData(session.data);
          }
        });
      }),
      share()
    )
  }

  private resetSession() {
    clearTimeout(this.sessionTimeout);
    this.currentSession = {
      _id: this.decodeJWT(this.dbTokenService.getToken()).sessionId,
      clientId: null,
      createdAt: null,
      updatedAt: null,
      data: null,
      locked: null
    };
  }

  private resumeData(state: AppState) {
    let config = state.config;
    const appState = state.state;
    if (!appState) {
      this.router.navigate(['landing']);
      setTimeout(() => {
        this._isStateChanged = false;
      }, 500);
      return;
    }
    for (let key in appState) {
      this.globalStorage.addState(key, appState[key]);
    }
    this.globalStorage.setConfig(config);
    if (appState.generate) {
      this.router.navigate(['generate']);
    } else if (appState.editor) {
      this.router.navigate(['editor']);
    } else if (appState.doctype) {
      this.router.navigate(['doctype']);
    } else {
      this.router.navigate(['landing']);
    }
    setTimeout(() => {
      this._isStateChanged = false;
    }, 500);
  }

}
