import { HttpClient } from '@angular/common/http';
import { ApiRoutesService } from './api-routes.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientModel } from '../interfaces/clientModel';
import { PartnerModel } from '../interfaces/partnerModel';

@Injectable({
	providedIn: 'root'
})
export class ClientService {
	constructor(private http: HttpClient, private apiRoutesService: ApiRoutesService) {}

	getClientInfo(): Observable<ClientModel> {
		return this.http.get<ClientModel>(this.apiRoutesService.CLIENT);
	}

	updateClientInfo(client: ClientModel): Observable<ClientModel> {
		return this.http.patch<ClientModel>(this.apiRoutesService.CLIENT, client);
	}

	getMailTemplates(): Observable<any[]> {
		return this.http.get<any[]>(this.apiRoutesService.MAIL_TEMPLATES);
	}

	getPartnersList(): Observable<PartnerModel[]> {
		return this.http.get<PartnerModel[]>(this.apiRoutesService.CLIENT + '/partners');
	}

	getOnboardingsCSV() {
		return this.http.get(this.apiRoutesService.CLIENT + '/onboardings/csv', { responseType: 'blob' });
	}
}
