import {animate, AnimationMetadata, query, style, transition, trigger} from '@angular/animations';

export function isNotIE(){
    return !(/msie\s|trident\/|edge\//i.test(window.navigator.userAgent));
};

const steps: AnimationMetadata[] = [
    query(
        ':enter',
        [style({opacity: 0})],
        {optional: true}
    ),
    query(
        ':leave',
        [style({opacity: 1}), animate('0.3s', style({opacity: 0}))],
        {optional: true}
    ),
    query(
        ':enter',
        [style({opacity: 0}), animate('0.3s', style({opacity: 1}))],
        {optional: true}
    )
];

const absoluteSteps: AnimationMetadata[] = [
    query(
        ':enter',
        [style({opacity: 0, width: '100%', height: '100%'})],
        {optional: true}
    ),
    query(
        ':leave',
        [style({opacity: 1, position: 'absolute', width: '100%', height: '100%'}), animate('0.3s', style({
            opacity: 0,
            position: 'absolute',
            width: '100%',
            height: '100%'
        }))],
        {optional: true}
    ),
    query(
        ':enter',
        [style({opacity: 0, position: 'absolute', width: '100%', height: '100%'}), animate('0.3s', style({
            opacity: 1,
            position: 'absolute',
            width: '100%',
            height: '100%'
        }))],
        {optional: true}
    )
];

export const routeAnimations =
    trigger('myAnimation', [
        transition(isNotIE, steps),
    ]);

export const animationWithAbsolute = trigger('myAnimation', [
    transition(isNotIE, absoluteSteps)
]);
