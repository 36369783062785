import {BehaviorSubject } from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  progress$ = new BehaviorSubject<number>(0);

  getProgress() {
    return this.progress$.value;
  }

  setProgress(percent: number) {
    percent = Math.min(Math.max(percent, 0), 100);
    this.progress$.next(percent);
  }
}
