import {Injectable, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {map} from 'rxjs/operators';

import {GlobalStorageService} from './global-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TitleService implements OnInit {

  customerName: string;
  defaultTitle: string;

  constructor(
    protected title: Title,
    private globalStorage: GlobalStorageService,
  ) {
    this.defaultTitle = 'Netfira Onboarding App';
    this.globalStorage.customerName
      .pipe(
        map((name: string) => {
          if (!name) {
            return 'Netfira Onboarding App';
          } else {
            return `${name} Onboarding App`;
          }
        })
      )
      .subscribe(
        (name: string) => {
          this.customerName = name;
          this.setTitle(this.customerName);
        }
      );
  }

  private ignoredUrl: string[] = [
    '/validation',
    '/dashboard',
    '/sessions',
  ];

  ngOnInit() {
  }

  checkTitle(url: string) {
    if (this.containsUrl(url)) {
      return;
    }
    this.setTitle(!!this.customerName ? this.customerName : this.defaultTitle);
  }

  private setTitle(title: string) {
    this.title.setTitle(title);
  }

  private containsUrl(url: string): boolean {
    let contains = false;
    this.ignoredUrl.forEach((item) => {
      if (contains) {
        return;
      }
      contains = url.indexOf(item) !== -1;
    });
    return contains;
  }

}
