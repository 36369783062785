import { AfterViewInit, Component, HostListener } from '@angular/core';
import { NavigationCancel, Router, RoutesRecognized } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { TitleService } from './services/title.service';

import { animationWithAbsolute } from './route-change.animation';
import { GlobalStorageService } from './services/global-storage.service';
import { AuthService } from './services/auth.service';
import { StateService } from './services/state.service';

import { environment } from '../environments/environment';

const versionInfo = require('!!raw-loader!../.versioninfo').default;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [animationWithAbsolute]
})
export class AppComponent {
	title = 'onboarding-app-V2';
	currentEng = false;
	defaultLanguage: string;
	displayDashboard: boolean;

	appVersion = versionInfo;

	ignoredUrl: Array<string>;
	config: object = {};

	currentUserName = null;
	showBackButton = false;
	year: number = new Date().getFullYear();
	supportedLanguages = ['en', 'de']

	@HostListener('window:offline', ['$event'])
	onOfflineEvent($event) {
		console.log('Of Line', $event);
	}

	@HostListener('window:online', ['$event'])
	onOnlineEvent($event) {
		console.log('On Line', $event);
	}

	constructor(
		private translate: TranslateService,
		protected router: Router,
		protected titleService: TitleService,
		private globalStorage: GlobalStorageService,
		private authService: AuthService,
		private stateService: StateService
	) {
		this.stateService.checkTokenRefresh();
		this.defaultLanguage = this.getUserPrefLang().toLowerCase();
		this.currentEng = this.defaultLanguage === 'en';
		translate.setDefaultLang(this.defaultLanguage);
		translate.use(this.defaultLanguage);

		this.ignoredUrl = ['/doctype', '/editor', '/generate', '/login'];

		this.router.events.subscribe((event: RoutesRecognized) => {
			if (event instanceof NavigationCancel) {
				this.displayDashboard = event.url === '/';
			} else if (event.url && event.urlAfterRedirects) {
				this.showBackButton = event.url !== '/' && event.url !== '/landing';
				this.displayDashboard = this.containsUrl(event.url) && this.containsUrl(event.urlAfterRedirects);
				this.titleService.checkTitle(event.url);
			}
		});
		this.authService.currentUser.subscribe((user) => {
			setTimeout(() => {
				this.currentUserName = null;
				if (!user) {
					return;
				}
				this.currentUserName = user.clientData.name;
			});
		});
		this.warningOnQuit();
	}

	private warningOnQuit() {
		const internalHandler = (e) =>  {
			if (this.router.url=== '/landing') {
				return;
			}
			e.preventDefault(); // required in some browsers
			e.returnValue = ""; // required in some browsers
			return "Changes you made may not be saved"; // only works in old browsers
		}
		window.addEventListener('beforeunload', internalHandler, true);
	}

	private getUserPrefLang():string {
		if (navigator.languages != undefined) {
			const lang = navigator.languages[0].split('-')[0].toLowerCase(); 
			if (this.supportedLanguages.includes(lang)) {
				return lang;
			}
		}
		return 'en';
	}
	onChangeLang() {
		this.currentEng = !this.currentEng;
		if (this.currentEng) {
			this.translate.use('en');
		} else {
			this.translate.use('de');
		}
	}

	private containsUrl(url: string): boolean {
		let contains = false;
		if (url === '/') {
			return false;
		}
		this.ignoredUrl.forEach((item) => {
			if (contains) {
				return;
			}
			contains = url.includes(item);
		});
		return !contains;
	}
}
