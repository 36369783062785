import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiRoutesService } from './api-routes.service';

import { CustomAttribute } from '../interfaces/customAttribute';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  constructor(
    private apiRoutes: ApiRoutesService,
    private http: HttpClient,
  ) { }

  getSettings = () => this.http.get(this.apiRoutes.SETTINGS);

  setSettings = (settings: object) => this.http.post(this.apiRoutes.SETTINGS, settings);

  customAttribute = (attribute: CustomAttribute, attrLenght: number, doctypeName: string) => {
    return this.http.post(this.apiRoutes.CUSTOM, {attribute, noOfCurrentAttribute: attrLenght, Doctype: doctypeName});
  }
}
