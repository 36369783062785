import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as Sentry from '@sentry/browser';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { ApiRoutesService } from './services/api-routes.service';
import { HttpMultiLoaderServiceService } from './services/http-multi-loader-service.service';

import { SnackbarModule } from 'ngx-snackbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShareModule } from './share.module';
import { Services } from './services';
import { NgbActiveModal,NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { environment } from '../environments/environment';

export const createTranslateLoader = (http: HttpClient, apiRoutesService: ApiRoutesService) => {
	return new HttpMultiLoaderServiceService(http, [{ prefix: './assets/i18n', suffix: '.json' }, { prefix: `${apiRoutesService.I18N}` }]);
};
switch (true) {
	case environment.production: {
		Sentry.init({
			dsn: 'https://e9c8f4bd00c649fd9a342c8f6d0c0129@sentry.alphax-ds.de/18',
			environment: 'Production',
			ignoreErrors: ['Non-Error exception captured with keys']
		});
		break;
	}
	case environment.staging: {
		Sentry.init({
			dsn: 'https://ea52edbe3500498caa9d94444b4fac5f@sentry.alphax-ds.de/17',
			environment: 'Staging',
			ignoreErrors: ['Non-Error exception captured with keys']
		});
		break;
	}
}

@NgModule({
	declarations: [AppComponent],
	providers: [NgbActiveModal, NgbTooltip, ...Services],
	imports: [
		ShareModule,
		BrowserAnimationsModule,
		SnackbarModule,
		AppRoutingModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient, ApiRoutesService]
			}
		})
	],
	exports: [BrowserAnimationsModule, AgGridModule],
	bootstrap: [AppComponent],
	entryComponents: []
})
export class AppModule {}
