import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ApiRoutesService} from './api-routes.service';

import {GenerateRequest} from '../interfaces/generateRequest';
import {SupportRequest} from '../interfaces/supportRequest';

export interface GenerateResponse {
  data: any;
  filter: string;
  loginCreated: boolean;
  specification: {};
  specificationError: {};
}

@Injectable({
  providedIn: 'root'
})
export class GenerateService {

  constructor(
    private http: HttpClient,
    private apiRoutes: ApiRoutesService,
  ) {
  }

  generate = (request: GenerateRequest) => this.http.post<GenerateResponse>(this.apiRoutes.GENERATE, request);

  support = (request: SupportRequest) => this.http.post(this.apiRoutes.SUPPORT, request);

  saveLogin = (request: GenerateRequest) => this.http.post(this.apiRoutes.SAVE_LOGIN, request);
}
