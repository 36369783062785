import {Injectable} from '@angular/core';

import * as cloneDeep from 'lodash.clonedeep';

import {Attribute} from '../interfaces/attribute';
import {MarkedAttribute} from '../interfaces/markedAttribute';
import {DocType} from '../interfaces/doctype';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Ticket} from '../onboarding/models/Ticket';
import {HttpClient} from '@angular/common/http';
import {ApiRoutesService} from './api-routes.service';
import {share, tap} from 'rxjs/operators';
import {ClientService} from './client.service';
import {ClientModel} from '../interfaces/clientModel';

@Injectable({
  providedIn: 'root'
})
export class GlobalStorageService {

  private config: any;
  private states: object;
  private tickets: Ticket[];


  customerName: Subject<string | null>;

  constructor(
    private http: HttpClient,
    private apiRoutesService: ApiRoutesService,
    private clientService: ClientService
  ) {
    this.config = new Object();
    this.states = new Object({
      newpartner: false,
      existingpartner: false,
      doctype: false,
      editor: false,
      generate: false,
    });
    this.customerName = new Subject();
  }

  addState(component: string, state: boolean) {
    const copy = Object.assign({}, this.states);
    copy[component] = state;
    this.states = copy;
  }

  getState(component: string): boolean {
    return this.states[component];
  }

  clearState() {
    Object.keys(this.states).forEach(
      (key) => {
        if (key !== 'newpartner' && key !== 'existingpartner') {
          this.states[key] = false;
        }
      }
    );
  }

  getConfig(): Observable<object> {
    if (Object.keys(this.config).length === 0) {
      return this.http.get(this.apiRoutesService.CLIENT_CONFIG).pipe(tap((m: object) => {
        this.setConfig(m);
      }), share());
    }
    const $config = new BehaviorSubject<object>(this.config);
    return $config.asObservable();
  }

  getClientInfo(): Observable<ClientModel> {
    return this.clientService.getClientInfo();
  }

  updateClientInfo(client): Observable<ClientModel> {
    return this.clientService.updateClientInfo(client);
  }

  getMailTemplates(): Observable<any[]> {
    return this.clientService.getMailTemplates();
  }

  getContext(): string {
    return this.config.data ? this.config.data : '';
  }

  getDoctype(): Array<DocType> | null {
    return this.config.docs ? this.config.docs : null;
  }

  getAttributes(): Array<Attribute> {
    const attributes: Array<Attribute> = cloneDeep(this.config.attributes);
    return attributes ? attributes : [];
  }

  getKeyValue(key: string) {
    return this.config[`${key}`];
  }

  setConfigKey(key: string, value: any) {
    const copy: object = Object.assign({}, this.config);
    copy[key] = value;
    this.config = copy;
  }

  setConfig(config: object) {
    this.config = config;
    this.setCustomerName();
  }

  getConfigObj() {
    return this.config;
  }

  addToConfig(config: object) {
    this.config = Object.assign(this.config, config);
  }

  setMarked(marked: MarkedAttribute[]) {
    this.config.marked = marked;
  }

  changeAttributes() {
    this.config.attributes = this.config.docType.attributes;
    this.config.attributes.forEach((attribute: Attribute) => {
      attribute.static = false;
      attribute.selection = null;
      attribute.value = null;
      attribute.filterChecked = false;
      attribute.filter = false;
    });
  }

  setCustomerName() {
    this.customerName.next(this.getKeyValue('customerName'));
  }

  getTickets(): Ticket[] {
    return this.tickets;
  }

  setTickets(tickets: Ticket[]) {
    this.tickets = tickets;
  }


}
