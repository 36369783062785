import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiRoutesService } from './api-routes.service';

@Injectable({
  providedIn: 'root'
})
export class WelcomeService {

  constructor(
    private http: HttpClient,
    private apiRoutesService: ApiRoutesService
  ) { }

  public newClientRequest = (data: object) => this.http.post(this.apiRoutesService.NEW_CLIENT_REQUEST, data);
}

