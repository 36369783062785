import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiRoutesService {
	private readonly API_BASE_ROUTE: string;
	public readonly LOGIN: string;
	public readonly LOGOUT: string;
	public readonly NEW_CLIENT_REQUEST: string;
	public readonly CLIENT_CONFIG: string;
	public readonly SUPPLIER: string;
	public readonly SUPPLIER_CHECK: string;
	public readonly SUPPLIER_NUMBER_CHECK: string;
	public readonly SUPPLIER_DOMAIN_CHECK: string;
	public readonly UPLOAD: string;
	public readonly UPLOAD_ADDITIONAL: string;
	public readonly PREDICT: string;
	public readonly I18N: string;
	public readonly SETTINGS: string;
	public readonly ATTRIBUTES: string;
	public readonly CUSTOM: string;
	public readonly ADD_CLIENT_TO_SUPPLIER: string;
	public readonly GENERATE: string;
	public readonly SUPPORT: string;
	public readonly SAVE_LOGIN: string;
	public readonly GET_VALIDATION: string;
	public readonly TICKETS: string;
	public readonly DASHBOARD: string;
	public readonly CLIENT: string;
	public readonly MAIL_TEMPLATES: string;
	public readonly SESSIONS: string;
	public readonly REFRESH_TOKENS: string;
	public readonly HUB_TOKEN: string;

	constructor() {
		this.API_BASE_ROUTE = environment.apiBaseUrl;
		this.LOGIN = `${this.API_BASE_ROUTE}/api/login`;
		this.LOGOUT = `${this.API_BASE_ROUTE}/api/logout`;
		this.NEW_CLIENT_REQUEST = `${this.API_BASE_ROUTE}/api/newClientRequest`;
		this.CLIENT_CONFIG = `${this.API_BASE_ROUTE}/api/config`;
		this.SUPPLIER = `${this.API_BASE_ROUTE}/api/supplier`;
		this.SUPPLIER_CHECK = `${this.API_BASE_ROUTE}/api/supplier?supplierName=`;
		this.SUPPLIER_NUMBER_CHECK = `${this.API_BASE_ROUTE}/api/supplier?supplierNumber=`;
		this.SUPPLIER_DOMAIN_CHECK = `${this.API_BASE_ROUTE}/api/check-domain-exists?supplierDomain=`;
		this.UPLOAD = `${this.API_BASE_ROUTE}/api/upload`;
		this.UPLOAD_ADDITIONAL = `${this.API_BASE_ROUTE}/api/uploadAdditional`;
		this.PREDICT = `${this.API_BASE_ROUTE}/api/predict`;
		this.I18N = `${this.API_BASE_ROUTE}/api/i18n`;
		this.SETTINGS = `${this.API_BASE_ROUTE}/api/settings`;
		this.ATTRIBUTES = `${this.API_BASE_ROUTE}/api/attributes`;
		this.CUSTOM = `${this.API_BASE_ROUTE}/api/customAttribute`;
		this.GENERATE = `${this.API_BASE_ROUTE}/api/generate`;
		this.SUPPORT = `${this.API_BASE_ROUTE}/api/support`;
		this.SAVE_LOGIN = `${this.API_BASE_ROUTE}/api/saveLogin`;
		this.GET_VALIDATION = `${this.API_BASE_ROUTE}/api/validate/ticket`;
		this.TICKETS = `${this.API_BASE_ROUTE}/api/tickets`;
		this.DASHBOARD = `${this.API_BASE_ROUTE}/api/dashboard`;
		this.CLIENT = `${this.API_BASE_ROUTE}/api/client`;
		this.MAIL_TEMPLATES = `${this.API_BASE_ROUTE}/api/mail-templates`;
		this.SESSIONS = `${this.API_BASE_ROUTE}/api/sessions`;
		this.REFRESH_TOKENS = `${this.API_BASE_ROUTE}/api/refresh-tokens`;
		this.HUB_TOKEN = `${this.API_BASE_ROUTE}/auth/hub`;
	}
}
