import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ApiRoutesService} from './api-routes.service';
import {catchError, share} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LandingpageService {

  constructor(
    private http: HttpClient,
    private apiRoutesService: ApiRoutesService,
  ) {
  }

  public getClientConfig = () => this.http.get(this.apiRoutesService.CLIENT_CONFIG);
}
