import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, mergeMap, share, tap} from 'rxjs/operators';

import {ApiRoutesService} from './api-routes.service';

import {PayloadJWT} from '../interfaces/payloadJWT';
import {GlobalStorageService} from './global-storage.service';
import {Router} from '@angular/router';
import * as Fingerprint from 'fingerprintjs/fingerprint';
import * as Sentry from '@sentry/browser';
import * as jwtDecode from 'jwt-decode';
import {DbTokenService} from './dbToken.service';
import {Injectable} from "@angular/core";

export interface JWT {
  accessToken: string;
  refreshToken: string;
}

export interface loginResponse {
  accessToken: string,
  refreshToken: string,
  setPassword: boolean,
  portalAuthorization: boolean
}

@Injectable()
export class AuthService {

  private readonly JWT_LOCALSTORAGE_KEY = 'JWT';
  private currentUserSubject: BehaviorSubject<PayloadJWT>;
  public currentUser: Observable<PayloadJWT>;
  private fingerprint = new Fingerprint().get();
  private refreshToken = null;
  private $refreshToken: Observable<boolean>;
  private tokenRefreshInProgress: boolean;

  constructor(
    private http: HttpClient,
    private globalStorage: GlobalStorageService,
    private apiRoutesService: ApiRoutesService,
    private router: Router,
    private dbTokenService: DbTokenService,
  ) {
    const jwt = this.getToken();
    if (!!jwt) {
      this.refreshToken = this.dbTokenService.getRefreshToken();
    }
    this.currentUserSubject = !!jwt ? new BehaviorSubject<PayloadJWT>(this.decodeJWT(jwt)) : new BehaviorSubject<any>(null);
    this.currentUser = this.currentUserSubject.asObservable();

    // Client consistence data in browser tabs.
    this.currentUser.subscribe(userData => {
      if (userData) {
        Sentry.configureScope(scope => {
          scope.setUser({id: userData.clientId, username: userData.clientData.name});
        });
      } else {
        Sentry.configureScope(scope => {
          scope.setUser({});
        });
      }
    });
  }

  private decodeJWT(JWT: string) {
    try {
      const payload = jwtDecode(JWT);
      return payload;
    } catch (e) {
      this.logout();
    }
  }

  public currentUserValue(): PayloadJWT {
    if (this.currentUserSubject) {
      return this.currentUserSubject.value;
    } else {
      return null;
    }
  }

  public onChangeSession(sessionId: string): Observable<boolean> {
    return this.onRefreshToken(sessionId);
  }

  public getToken(): string {
    return localStorage.getItem(this.JWT_LOCALSTORAGE_KEY);
  }

  public onRefreshToken(sessionId?: string): Observable<boolean> {
    if (!this.refreshToken) {
      return of(false);
    }
    const data = {
      refreshToken: this.refreshToken,
      fingerprint: this.fingerprint
    };

    (sessionId) ? data['sessionId'] = sessionId : null;
    this.tokenRefreshInProgress = true;
    this.$refreshToken = this.http.post<loginResponse>(this.apiRoutesService.REFRESH_TOKENS, data)
      .pipe(
        tap(data => this.onLogin(data.accessToken, data.refreshToken)),
        mergeMap((data: loginResponse) => {
          return of(true);
        }),
        catchError(err => {
          this.logout();
          return of(false);
        }));
    return this.$refreshToken;
  }

  public login(hash: string, password?: string, login?: string) {
    let data = {token: hash, fingerprint: this.fingerprint};
    if (login && password) {
      let d = {
        username: login,
        password: password
      };
      data['portalCredentials'] = d;
    } else if (password && password != '') {
      data['password'] = password;
    }
    return this.http.post<loginResponse>(this.apiRoutesService.LOGIN, data)
      .pipe(
        tap((data) => {
          // Checking does response has access token and refresh token
          // Logged out client only if refresh token has not been successfully stored in Db.
          if (data.accessToken && data.refreshToken) {
            this.onLogin(data.accessToken, data.refreshToken);
          }
        })
      );
  }

  private onLogin(accessToken: string, refreshToken: string) {
    const payload = this.decodeJWT(accessToken);
    this.refreshToken = refreshToken;
    localStorage.setItem(this.JWT_LOCALSTORAGE_KEY, accessToken);
    this.currentUserSubject.next(payload);
    // Checking does refresh token already stored in Db.
    const token = this.dbTokenService.getRefreshToken();
    if (token) {
      // Updating token in db because it's already stored.
      this.dbTokenService.updateRefreshToken(refreshToken);
    } else {
      // Token does not exist in Db, saving token in Db.
      this.dbTokenService.setRefreshToken(refreshToken);
    }
  }

  public onHubLogin(accessToken: string, refreshToken: string) {
    this.onLogin(accessToken, refreshToken);
  }

  public setCurrentUser(user: PayloadJWT) {
    this.currentUserSubject.next(user);
  }

  public logout() {
    localStorage.removeItem(this.JWT_LOCALSTORAGE_KEY);
    this.dbTokenService.clear();
    this.currentUser = null;
    this.refreshToken = null;
    this.currentUserSubject.next(null);
    this.globalStorage.setConfig({});
    if (this.refreshToken) {
      this.http.post(this.apiRoutesService.LOGOUT, {refreshToken: this.refreshToken})
        .pipe(catchError(err => of(null)), share()).subscribe(d => d);
    }
    this.router.navigate(['']);
  }

  public hubLogin(token: string) {
    const url = `?token=${token}&fingerprint=${this.fingerprint}&target=user`;
    window.location.href = this.apiRoutesService.HUB_TOKEN + url;
  }

}
