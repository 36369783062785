import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class DbTokenService {

  setRefreshToken(token: string): void {
    return window.localStorage.setItem('refreshToken', token)
  }

  updateRefreshToken(token): void {
    return window.localStorage.setItem('refreshToken', token);
  }

  getRefreshToken(): string {
    return window.localStorage.getItem('refreshToken');
  }
  getToken(): string {
    return window.localStorage.getItem('JWT');
  }

  clear() {
    return window.localStorage.removeItem('refreshToken');
  }
}
