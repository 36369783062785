import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./legacy/legacy.module').then((m) => m.LegacyModule)
	},
	{
		path: 'validation',
		loadChildren: () => import('./validation/validation.module').then((m) => m.ValidationModule)
	},
	{
		path: 'onboarding',
		loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'client',
		loadChildren: () => import('./client/app.client-management.module').then((m) => m.AppClientManagementModule),
		canActivate: [AuthGuard]
	},
	{
		path: ':hash',
		redirectTo: 'login/:hash'
	},
	{
		path: '**',
		redirectTo: ''
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { enableTracing: false })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
