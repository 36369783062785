import {ErrorHandler, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import {TranslateModule} from '@ngx-translate/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {globalComponents} from './globalComponents';
import {BackButtonComponent} from './globalComponents/back-button/back-button.component';
import {GlobalErrorService} from './services/global-error.service';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {SpinnerService} from "./services/spinner.service";

@NgModule({
  declarations: [...globalComponents],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CodemirrorModule,
    TranslateModule,
    ScrollingModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    CodemirrorModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    ScrollingModule,
    BackButtonComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorService
    },
    SpinnerService
  ],
  entryComponents: [...globalComponents]
})
export class ShareModule {

}
