import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiRoutesService } from './api-routes.service';
import { GlobalStorageService } from './global-storage.service';
import { GlobalErrorService } from './global-error.service';
import { PartnerModel } from '../interfaces/partnerModel';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class NewPartnerService {
	public partnerList: Array<PartnerModel> = [];

	constructor(
		private http: HttpClient,
		private apiRoutesService: ApiRoutesService,
		private globalError: GlobalErrorService,
		private globalStorageService: GlobalStorageService
	) {}

	checkSupplier = (supplierName: string): Observable<any> => {
		return this.http.get(`${this.apiRoutesService.SUPPLIER_CHECK}` + supplierName);
	};

	checkSupplierNumber = (supplierNumber: string): Observable<any> => {
		return this.http.get(`${this.apiRoutesService.SUPPLIER_NUMBER_CHECK}` + supplierNumber);
	};

	checkSupplierDomain = (supplierDomain: string, clientName: string): any => {
		return this.http.get(`${this.apiRoutesService.SUPPLIER_DOMAIN_CHECK}` + supplierDomain + `&clientName=` + clientName);
	};

	readFile(file: File): Promise<any> {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onerror = () => this.globalError.handleError(new Error('global.default_error_message'));
		return new Promise((resolve) => {
			reader.onload = () => {
				const readerResult = reader.result.toString().replace(/^data:application\/pdf;base64,/, '');
				this.globalStorageService.setConfigKey('base64', readerResult);
				resolve(readerResult);
			};
		});
	}
}
